import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  useRef
} from "react";
import { Data, Events, Overrides } from "@transformd-ltd/sdk";
import { Helmet } from "react-helmet";
import {
  Formatic,
  CustomCheckboxList,
  CustomRadioList,
  RelationshipCustom,
  CompanySearchAutocomplete
} from "../components";
import type { Verification } from "../types/Verification";

const formProps = {
  apiServerUrl: "https://api-staging.transformd.com",
  serverUrl: "https://api-staging.transformd.com/graphql",
  subscriptionServerUrl: "wss://api-staging.transformd.com/subscriptions"
};

export type EmitterData = {
  fieldID: string;
  newValue: string | string[];
  oldValue: string | string[];
};

export type FormData = {
  [key: string]: any;
  getEmitter: () => any;
};

const Heading = () => {
  const refreshPage = () => {
    if (typeof window !== undefined) {
      window.location.reload();
    }
  };

  return (
    <Helmet title="APLYiD Onboarding - Real Estate Demo" defer={false}>
      <body className="px-4" />
    </Helmet>
  );
};

const nonFields = ["heading", "richTextArea"];

const RealEstatePage: FunctionComponent = (): ReactElement => {
  const [isReady, setIsReady] = useState(false);
  const [page, setPage] = useState<string>("624ce8c22b1d8500091c18c7");
  const [name, setName] = useState<string>("");
  const [verificationResult, setVerificationResult] = useState<
    Verification | {}
  >({});
  const [hasValuables, setHasValuables] = useState<string>("NO");
  const [status, setStatus] = useState<string>("base");
  const [data] = useState<FormData>(new Data());
  const [submissionId, setSubmissionId] = useState<string>("");
  const bodyRef = useRef<HTMLDivElement>(null);

  const order = ({
    dataForm,
    submission,
    currentPage,
    visibleFields,
    event
  }) => {
    const { fields, sections, pages } = dataForm;

    const pagesMap = pages
      ?.map(paged =>
        paged?.sections?.map(sectionLol => {
          const newFields = sections
            ?.filter(section => section.id === sectionLol.id)
            ?.map(section => {
              const newSection = section.fields.map(fieldLol => {
                const newFields = fields
                  ?.filter(field => field.id === fieldLol.id)
                  ?.filter(field => !nonFields.includes(field.renderer))
                  ?.map(field => ({
                    fieldId: field.id,
                    value: submission?.fieldsById?.[field?.id]?.value ?? null,
                    errors: visibleFields?.[field?.id]?.errors
                      ? Object.keys(visibleFields?.[field?.id]?.errors)
                      : []
                  }));

                return newFields;
              });
              return newSection;
            })
            ?.flat(2);

          return {
            page: paged?.attributes?.label,
            id: paged?.id,
            currentPage: paged?.id === page,
            fields: newFields
          };
        })
      )
      ?.flat()
      ?.filter(paged => paged?.fields?.length)
      ?.filter(paged => paged?.currentPage)
      ?.shift();

    const hasValues = pagesMap?.fields?.some(field => field?.value);
    const hasErrors = pagesMap?.fields?.some(field => field?.errors?.length);

    // console.log({ pagesMap, hasValues, hasErrors });

    if (!hasValues && !hasErrors) {
      return "base";
    }

    if (hasValues && !hasErrors) {
      if (event === "valueChanged") {
        return "validating";
      }
      return "success";
    }

    if (hasErrors) {
      return "error";
    }

    return "base";
  };

  const getValues = (newData, event) => {
    if (
      newData?.formatic?.dataForm?.pages?.length &&
      newData?.formatic?.data?.submission &&
      newData?.formatic?.visibleFields
    ) {
      const newStatus = order({
        dataForm: newData.formatic.dataForm,
        submission: newData.formatic.data.submission,
        visibleFields: newData.formatic.visibleFields,
        currentPage: page,
        event
      });
      return setStatus(newStatus);
    }
    return setStatus("base");
  };

  const getVerificationData = renderData => {
    const verification: Verification | { type: null } =
      renderData?.formatic?.data?.submission?.fieldsById?.[
        "627853341fd4a100512c7334"
      ]?.value?.verification ?? {};

    // console.log(verification);

    if (verification && verification?.type) {
      setVerificationResult(verification);
    }
  };

  const values = newData => {
    const formFieldsById = newData?.formatic?.data?.form?.fieldsById
      ? Object.values(newData?.formatic?.data?.form?.fieldsById)
      : [];
    const valuesFieldsById = newData?.formatic?.data?.submission?.fieldsById
      ? Object.values(newData?.formatic?.data?.submission?.fieldsById)
      : [];
    let arr3 = formFieldsById
      .map(itm => ({
        ...valuesFieldsById.find(item => item.id === itm.id && item),
        ...itm
      }))
      .filter(item => item?.value)
      .map(item => ({ tag: item.attributes.tag, ...item }));

    if (typeof window !== "undefined") {
      window?.parent?.postMessage(
        {
          type: "form-data",
          message: arr3
        },
        "*"
      );
    }
  };

  const inputChangeListener = () => {
    const emitter = data?.getEmitter();

    // Listen to page render events and set id of current page
    emitter.on(Events.PageRender, page => {
      const renderData = data?.store?.getState();
      values(renderData);
      if (typeof window !== "undefined" && renderData?.formatic?.submissionId) {
        const height = bodyRef?.current?.clientHeight ?? 0;
        window?.parent?.postMessage(
          {
            type: "iframe-height",
            message: `${height + 30}px`
          },
          "*"
        );
        setSubmissionId(renderData?.formatic?.submissionId);

        if (submissionId !== renderData?.formatic?.submissionId) {
          window?.parent?.postMessage(
            {
              type: "submission-id",
              message: renderData?.formatic?.submissionId
            },
            "*"
          );
        }
      }
      getVerificationData(renderData);
      setPage(page.id);
      getValues(renderData, "pageRender");
    });

    // Listen to field change events and update state of some values (name,valuables)
    emitter.on(Events.ValueChanged, (emitterData: EmitterData) => {
      const valueChangedData = data?.store?.getState();
      if (
        typeof window !== "undefined" &&
        valueChangedData?.formatic?.submissionId
      ) {
        const height = bodyRef?.current?.clientHeight ?? 0;
        window?.parent?.postMessage(
          {
            type: "iframe-height",
            message: `${height + 30}px`
          },
          "*"
        );
        values(valueChangedData);
      }
      if (emitterData.fieldID === "624ce9f92b1d8500091c18d3") {
        setName(emitterData?.newValue?.toString());
      }
      if (emitterData.fieldID === "624e12d62b1d85001f01a916") {
        setHasValuables(emitterData?.newValue?.toString());
      }
      getValues(valueChangedData, "valueChanged");
    });

    emitter.on(Events.FieldValidated, () => {
      const validationData = data?.store?.getState();
      if (typeof window !== "undefined") {
        const height = bodyRef?.current?.clientHeight ?? 200;
        window?.parent?.postMessage(
          {
            type: "iframe-height",
            message: `${height + 30}px`
          },
          "*"
        );
      }
      getValues(validationData, "fieldValidated");
    });

    emitter.on(Events.FieldError, () => {
      const errorData = data?.store?.getState();
      if (typeof window !== "undefined") {
        const height = bodyRef?.current?.clientHeight ?? 0;
        window?.parent?.postMessage(
          {
            type: "iframe-height",
            message: `${height + 30}px`
          },
          "*"
        );
      }
      getValues(errorData, "fieldError");
    });
  };

  useEffect(() => {
    if (isReady) {
      inputChangeListener();
    }
  }, [page, isReady]);

  useEffect(() => {
    if (typeof window !== undefined) {
      window?.localStorage?.removeItem("formatic-581");
      Object.keys(window?.sessionStorage).map(key => {
        window?.localStorage?.removeItem(key);
      });
      setIsReady(true);
    }
  }, []);

  return (
    <div ref={bodyRef}>
      <Heading />
      {isReady && (
        <Formatic
          {...formProps}
          data={data}
          formId={581}
          apiKey="afdcDf159365F9d9A6d12E334be147562A97F2B2b3a46D6A15F0cF611240Ab63"
          environment="Staging"
          channel="master"
          initialValues={{}}
          config="default"
          theme="transformd"
        >
          <Overrides.OverrideFieldContainer
            component={RelationshipCustom}
            type="personRelationship"
          />
          <Overrides.OverrideFieldContainer
            component={CustomCheckboxList}
            type="checkboxList"
          />
          <Overrides.OverrideFieldContainer
            component={CustomRadioList}
            type="radioList"
          />
          <Overrides.OverrideFieldContainer
            component={CompanySearchAutocomplete}
            type="companySearch"
          />
          <Overrides.OverrideFieldContainer
            type="text"
            id="62d4b4e0215a3c049d39a133"
            component={() => <span />}
          />
        </Formatic>
      )}
    </div>
  );
};

export default RealEstatePage;
